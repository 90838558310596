<template>
	<div
		class="jumbotron jumbotron-fluid m-0 hero-img"
		:style="{ backgroundImage: 'url(' + propsObj.header_image + ')' }"
	>
		<div class="container hero-text">
			<div class="row">
				<div class="col-md-12">
					<p class="hero-title">{{ propsObj.header_title }}</p>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<p class="hero-quote">{{ propsObj.header_quote }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		propsObj: {
			header_image: { type: String },
			header_title: { type: String },
			header_quote: { type: String },
		},
	},
};
</script>

<style scoped>
.hero-img {
	/* background-image: url('../assets/landschap.jpg'); */
	background-position: center center;
	background-repeat: no-repeat;
	min-height: 240px;
}

p {
	color: white;
	text-align: left;
	text-shadow: 1px 1px #636363;
}

.hero-title {
	font-size: 26pt;
	font-weight: 400;
	font-style: normal;
	text-transform: uppercase;
	text-align: right;
}

.hero-quote {
	/* font-size: 21px; */
	font-weight: 400;
	font-style: italic;
	text-align: right;
}

@media only screen and (min-width: 576px) {
	.hero-img {
		background-size: 100%;
	}
}
</style>
