<template>
    <div>
        <v-icon :name="iconName" :scale="2" class="d-block d-lg-none" @click="clickIcon" />

        <ul class="navlist" :class="{ 'd-block': displayMenu }">
            <li v-for="(item, i) in items" :key="i">
                <router-link :to="item.path" :class="{ 'router-link-active': subIsActive(item) }">{{
                    item.title
                }}</router-link>
                <ul class="subnavlist">
                    <li v-for="child in item.children" :key="child.title">
                        <router-link :to="child.path">{{ child.title }}</router-link>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import 'vue-awesome/icons/bars';
import 'vue-awesome/icons/times';
import 'vue-awesome/icons/caret-down';
import Icon from 'vue-awesome/components/Icon';

export default {
    data: () => ({
        items: [],
        iconName: 'bars',
        displayMenu: false
    }),

    props: {
        pages: { type: Array },
    },

    components: {
        'v-icon': Icon,
    },

    created() {
        // console.clear();
        // console.log(this.$vnode.tag.replace(/vue-component-\d+-/i, ''));

        let last;
        for (let i = 0; i < this.pages.length; i++) {
            let element = this.pages[i];
            if (element.menu_item) {
                if (element.level == 1) {
                    element.children = [];
                    this.items.push(element);
                    last = element;
                }
                if (element.level == 2) {
                    last.children.push(element);
                }
            }
        }
    },

    methods: {
        clickIcon() {
            if (!this.displayMenu) {
                this.displayMenu = true;
                this.iconName = 'times';
            } else {
                this.displayMenu = false;
                this.iconName = 'bars';
            }
        },

        toggleChildren(aa) {
            Vue.set(this.items[aa], 'visible', !this.items[aa].visible);
        },

        subIsActive(item) {
            // checks if children of item are active to make parent active also 
            let retval = false;
            if (item.children.length > 0) {
                item.children.forEach(element => {
                    if (this.$route.path.indexOf(element.path) != -1) {
                        retval = true;
                    }
                });
            }

            return retval;
        }
    },
};
</script>

<style scoped>
.fa-icon {
    fill: #2bc10c;
}

ul.navlist {
    font-family: 'Montserrat';
    font-size: 17px;
    font-weight: 700;
    z-index: 99;
}

ul.navlist a {
    text-decoration: none;
    /* color: white; */
    display: block;
}

ul.navlist,
ul.navlist ul,
ul.navlist li {
    margin: 0;
    padding: 0px;
    list-style-type: none;
}

/* main menu items */
ul.navlist li {
    float: left;
}

ul.navlist li a {
    padding: 12px 18px;
    text-align: left;
}

ul.navlist li a:hover {
    color: #2bc10c;
}

ul.subnavlist {
    color: white;
    display: none;
}

ul.subnavlist li {
    float: none;
}

/* submemnu display when hovered */
ul.navlist li:hover ul.subnavlist {
    display: block;
    position: absolute;
    background-color: #2bc10c;
    width: 300px;
    /* margin-top: 4rem; */
    z-index: 99;
}

ul.navlist li:hover ul.subnavlist li:hover {
    background-color: #2b9400;
}

ul.navlist li:hover ul.subnavlist li a {
    color: white;
    padding: 8px 12px;
}

/* ul.navlist li:hover ul.subnavlist li a:before {
	content: "- ";
} */

/* @media only screen and (min-width: 576px) {
	.hero-img {
		background-size: 100%;
	}
} */

.router-link-active {
    color: #2bc10c;
    /* background-color: #2b9400; */
}

/* medium screen size and below */
@media (max-width: 992px) {
    .fa-icon {
        margin: 0;
        padding: 0;
    }

    ul.navlist {
        position: absolute;
        top: 80px;
        left: -15px;
        display: none;
        color: white;
        background-color: #2bc10c;
    }

    ul.navlist li {
        float: none;
    }

    ul.navlist li a:hover {
        color: white;
    }

    /* display when clicked */
    ul.navlist li:hover ul.subnavlist {
        display: block;
        /* position: absolute; */
        background-color: #2bc10c;
        border: #2b9400 1px solid;
        left: 0px;
        z-index: 99;
    }

    ul.navlist li:hover ul.subnavlist li a:before {
        content: "> ";
    }

    .router-link-active {
        color: white;
        background-color: #2b9400;
    }
}
</style>
